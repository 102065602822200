.prompt {
  display: block;
}

@media screen and (min-width: 850px) {
  .App {
    padding-bottom: 20px;
    padding-top: 35px;
  }
}
