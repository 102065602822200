.reviews__title{
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    line-height: 1.3;
    font-family: "Roboto", sans-serif;
    color: #636b6f;
}

.reviews__score{
    color: #333; 
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
}

.reviews__wrapper{
    display: flex;
    margin-top: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

.star__wrapper{
    display: flex;
    gap: 3px;
    margin-top: 3px;
    margin-left: 10px;
}

.reviews__number{
    margin-top: 5px;
    font-size: 12px;
    color: #898989;
    line-height: 24px;
    margin-left: 30px;
}

.lines__list{
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
}

.lines__item{
    display: flex;
    align-items: center;
    gap: 5px;
}

.first__line{
    position: relative;
    width: 200px;
    height: 10px;
    border-radius: 10px;
    background-color: #d9d9d9;
}

.numbers{
    width: 15px;
    color: #737373;
    font-size: 11px;
}

.first__line--green{
    position: absolute;
    width: 180px;
    height: 10px;
    border-radius: 10px;
    background: #00875f;
}

.second__line--green{
    position: absolute;
    width: 25px;
    height: 10px;
    border-radius: 10px;
    background: #00875f;
}

.third__line--green {
    position: absolute;
    width: 12px;
    height: 10px;
    border-radius: 10px;
    background: #00875f;
}

.fourth__line--green {
    position: absolute;
    width: 8px;
    height: 10px;
    border-radius: 10px;
    background: #00875f;
}

.images__wrapper{
    display: flex;
    gap: 3px;
}

.item__wrapper{
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
}

.item__wrapper:last-of-type{
    margin-bottom: 0;
}

.item__logo{
    border-radius: 50%;
}

.item__title{
    color: #4c4c4c;
    font-size: 13px;
    font-weight: 500;
}

.item__thumb{
    display: flex;
    gap: 10px;
}

.item__date{
    color: #aaa;
    font-size: 12px;
    font-weight: 400;
}

.item__text{
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: #636b6f;
}

.triangle{
    border-bottom: 10px solid #e5e5e5;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-bottom: -10px;
    position: absolute;
    top: -9px;
    width: 0;
}

.review__box{
    min-height: 95px;
    background: #e5e5e5;
    margin-top: 12px;
    padding: 12px;
    position: relative;
}

.item__like{
    color: #757575;
    font-size: 10px;    
}

.item__flex{
    display: flex;
    justify-content: space-between;
}

.item__likes{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review__title{
    font-weight: 500;
    font-size: 14px;
    color: #636b6f;
    line-height: 24px;
}

.review__description{
    color: #636b6f;
    font-size: 14px;
    line-height: 24px;
}

.reviews__button{
    padding-top: 2px;
    min-width: 100%;
    max-width: 300px;
    height: 35px;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 500;
    text-align: start;

    border-bottom: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    color: green;
    margin-top: 20px;
    margin-bottom: 20px;
}

.green__star{
    width: 12px;
    height: 12px;
}

.user__wrapper{
    display: none;
}

.green__star{
    width: 12px;
    height: 12px;
}

@media screen and (min-width: 850px) {
    .reviews__title{
        margin-top: 20px;
        margin-left: 20px;
        font-size: 14px;
    }

    .reviews__wrapper{
        padding-left: 60px;
        gap: 35px;
    }

    .reviews__score{
        color: #333;
        font-size: 64px;
        font-weight: 100;
        line-height: 64px;
    }

    .green__star {
        width: 17px;
        height: 17px;
    }

    .star__wrapper {
        gap: 5px;
        margin-left: -8px;
    }

    .user__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #636b6f;
        line-height: 24px;
    }

    .reviews__number{
        margin-top: -5px;
    }

    .reviews__button{
        display: flex;
        justify-content: center;
    }

    .item__wrapper{
        padding-left: 20px;
    }

    .review__box{
        width: 610px;
    }

    .item__text{
        width: 610px;
    }

    .numbers{
        font-size: 12px;
    }

    .first__line--green{
        display: none;
    }

    .second__line--green{
        display: none;
    }

    .third__line--green{
        display: none;
    }

    .fourth__line--green{
        display: none;
    }

    .first__line{
        height: 20px;
        border-radius: 0;
    }

    .desktop_one{
        display: block;
        width: 369px;
        background-color: #57bb8a;
    }

    .desktop_two{
        display: block;
        width: 54px;
        background-color: #9ace6a;
    }

    .desktop_three {
        display: block;
        width: 22.5px;
        background-color: #ffcf02;
    }

    .desktop_four{
        display: block;
        width: 10px;
        background-color: #ffb235;
    }

    .desktop_five {
        display: block;
        width: 10px;
        background-color: #ff8c5a;
    }

    .green__star {
        width: 17px;
        height: 17px;
    }

    .images__wrapper{
        gap: 6px;
    }
}

@media screen and (max-width: 355px) {
    .first__line{
        width: 160px;
    }

    .first__line--green{
        width: 140px;
    }

}